import * as React from "react"
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout/Layout'

const config = require('../../data/siteConfig')

const ArticlesContentsPage = (props:any) => {
    const postList = props.data.allMarkdownRemark;
    const pageTitle="Лекции и статьи";
    const postTypes = "";
    const subTitle = "";
    
    return (
      <Layout>
         <Helmet defer={false}>
              <title>{config.siteTitle}</title>
              <link rel="canonical" href={`${config.siteUrl}`} />
         </Helmet>
         <div className="pageContent">
            <h1>{pageTitle}</h1>
            <h2>{postTypes}</h2>          
            <p className="subTitle">{subTitle}</p>

            {postList.edges.map(({ node }:any, i:number) => (

                <Link to={`${node.fields.slug}`} key={i} className="link link-to-post" >                   
                    <div className="post-list-item">
                    <h3>{node.frontmatter.title}</h3>
                    <span className="postDate">{node.frontmatter.date}</span>
                    <p>{node.excerpt}</p>
                    </div>
                </Link>                
            ))}
         </div>     
      </Layout>
    )
}
  
  export default ArticlesContentsPage;
  
export const listArticles = graphql`

query ListArticles {
    allMarkdownRemark(filter: {frontmatter: {posttype: {eq: "article"} }} ) {
        edges {
            node {
                excerpt(pruneLength: 250)
                fields{
                slug
                }
                html
                frontmatter {
                date
                title
                posttype
                }
            }
        }
    }
}
`